import { lazy } from "react";
import { Outlet, useRoutes } from "react-router-dom";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";

const Home = lazy(() => import("src/pages/Home"));
const PrivacyPolicy = lazy(() => import("src/pages/PrivacyPolicy"));
const Login = lazy(() => import("src/pages/Login"));
const Unlock = lazy(() => import("src/pages/Unlock"));
const Register = lazy(() => import("src/pages/Register"));
const Wallet = lazy(() => import("src/pages/Wallet"));
const Settings = lazy(() => import("src/pages/Settings"));
const Users = lazy(() => import("src/pages/Users"));
const Transactions = lazy(() => import("src/pages/Transactions"));
const Schedules = lazy(() => import("src/pages/Schedules"));
const OTP = lazy(() => import("src/pages/OTP"));
const TwoFactorValidate = lazy(() => import("src/pages/TwoFactorValidate"));
const MainLayout = lazy(() => import("src/layout/Main"));
const Results = lazy(() => import("src/components/Results"));

const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/signin",
      element: <PublicRoute element={<Login />} />,
    },
    {
      path: "/unlock",
      element: <PublicRoute element={<Unlock />} />,
    },
    {
      path: "/signup",
      element: <PublicRoute element={<Register />} />,
    },
    {
      path: "/otp-verification",
      element: <PublicRoute element={<OTP />} />,
    },
    {
      path: "/2fa-validate",
      element: <PublicRoute element={<TwoFactorValidate />} />,
    },
    {
      path: "/main",
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      children: [
        {
          path: "wallet",
          element: <PrivateRoute element={<Wallet />} />,
        },
        {
          path: "settings",
          element: <PrivateRoute element={<Settings />} />,
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <MainLayout>
          <Outlet />
        </MainLayout>
      ),
      children: [
        {
          path: "users",
          element: <AdminRoute element={<Users />} />,
        },
        {
          path: "transactions",
          element: <AdminRoute element={<Transactions />} />,
        },
        {
          path: "schedules",
          element: <AdminRoute element={<Schedules />} />,
        },
        {
          path: "wallet",
          element: <PrivateRoute element={<Wallet />} />,
        },
        {
          path: "settings",
          element: <AdminRoute element={<Settings />} />,
        },
      ],
    },
    {
      path: "*",
      element: (
        <Results
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          height="100vh"
          extra
        />
      ),
    },
  ]);
};

export default Router;
