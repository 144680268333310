import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoute = ({ element }) => {
  const auth = useSelector((state) => state.auth);
  const isAuthenticated = auth?.user && auth?.token && auth?.tfa_validated;
  const isAdmin = auth?.user?.role === "admin";

  return isAuthenticated && isAdmin ? element : <Navigate to="/signin" />;
};

export default AdminRoute;
