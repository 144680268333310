import * as API from "src/api";
import {
  SET_TFA_VALIDATED,
  SET_USER,
  SET_TOKEN,
  SET_LOADING,
} from "src/constants/actionTypes";
import { toast_error } from "src/messages";

export const signUp = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });

    const { data } = await API.signUp(formData);

    dispatch({ type: SET_TOKEN, payload: data.token });
    dispatch({ type: SET_LOADING, payload: false });
    history("/otp-verification");
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: false });

    return error.response.status;
  }
};

export const signIn = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING, payload: true });

    const { data } = await API.signIn(formData);
    const { user, token } = data;

    dispatch({ type: SET_USER, payload: user });
    dispatch({ type: SET_TOKEN, payload: token });
    dispatch({ type: SET_LOADING, payload: false });

    if (user.tfa_enabled) {
      await dispatch({ type: SET_TFA_VALIDATED, payload: false });
      history("/2fa-validate");
    } else if (user.role === "admin") {
      await dispatch({ type: SET_TFA_VALIDATED, payload: true });
      history("/admin/users");
    } else {
      await dispatch({ type: SET_TFA_VALIDATED, payload: true });
      history("/main/wallet");
    }
    return '';
  } catch (error) {
    dispatch({ type: SET_LOADING, payload: false });
    toast_error(error.response?.data.message);
    return error.response?.data.message;
  }
};

export const googleSignIn = (userToken, history) => async (dispatch) => {
  try {
    const { data } = await API.googleSignIn(userToken);
    const { user, token } = data;

    dispatch({ type: SET_USER, user });
    dispatch({ type: SET_TOKEN, token });
    dispatch({ type: SET_TFA_VALIDATED, payload: true });

    if (user.role === "user") {
      history("/main/wallet");
    } else {
      history("/admin/users");
    }
  } catch (error) {
    console.log("googleSignIn error", error);
    return error.response.status;
  }
};

export const lockUser = () => async (dispatch) => {
  try {
    const { data } = await API.lockUser();
    const { user } = data;

    dispatch({ type: SET_USER, payload: user });
  } catch (error) {
    console.log("lock user error:", error);
  }
};

export const getUserById = (user_id) => async (dispatch) => {
  try {
    const { data } = await API.getUserById(user_id);
    const { user } = data;

    dispatch({ type: SET_USER, payload: user });
  } catch (error) {
    dispatch({ type: SET_USER, payload: null });
    dispatch({ type: SET_TOKEN, payload: null });
    dispatch({ type: SET_TFA_VALIDATED, payload: false });
    localStorage.clear();

    console.log(error.response.data.message);
  }
};
