import React from "react";
import ReactDOM from "react-dom/client";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import i18n from "./translation";
import store from "./store";
import App from "./App";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="1097197299432-icqhu5srv5mntplvk36auutvhv8sta7o.apps.googleusercontent.com">
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
