import { combineReducers } from "redux";

import auth from "./auth";
import ui from "./ui";
import admin from "./admin";

export default combineReducers({
  auth,
  ui,
  admin,
});
