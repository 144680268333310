import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element }) => {
  const auth = useSelector((state) => state.auth);
  const isAuthenticated = auth?.token && auth?.tfa_validated;

  return isAuthenticated ? element : <Navigate to="/signin" />;
};

export default PrivateRoute;
