import { useEffect, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SET_TOKEN, SET_TFA_VALIDATED } from "src/constants/actionTypes";
import { getUserById } from "./store/actions/auth";
import Router from "src/router";
import { Styles } from "./styles/styles";

import Loading from "src/components/Loading";
import "./index.css"
const App = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!user) {
      const token = localStorage.getItem("profile");

      if (token) {
        const { sub } = jwtDecode(token);

        dispatch({ type: SET_TOKEN, payload: token });
        dispatch({ type: SET_TFA_VALIDATED, payload: true });
        dispatch(getUserById(sub));
      }
    }
  }, [user, dispatch]);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <ToastContainer />
        <Styles />
        <Router />
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
