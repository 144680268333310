import * as TYPES from "src/constants/actionTypes";

const initialState = {
  token: "BTC",
  wallet_tab: "receive",
  balances: {},
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.SET_UI_TOKEN_BALANCE: {
      const { token, balance } = action.payload;
      return { ...state, balances: { ...state.balances, [token]: balance } };
    }
    case TYPES.SET_UI_TOKEN_BALANCES: {
      const balances = action.payload;
      return { ...state, balances };
    }
    case TYPES.SET_UI_TOKEN:
      return { ...state, token: action.payload, wallet_tab: "receive" };
    case TYPES.SET_UI_WALLET_TAB:
      return { ...state, wallet_tab: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
