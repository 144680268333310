//Using Constants To Prevent Potential Misspells
export const LOGOUT = "LOGOUT";
export const TRANSFER = "TRANSFER";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_TFA_VALIDATED = "SET_TFA_VALIDATED";
export const SET_LOADING = "SET_LOADING";

// UI related actions
export const SET_UI_TOKEN = "SET_UI_TOKEN";
export const SET_UI_WALLET_TAB = "SET_UI_WALLET_TAB";
export const SET_UI_TOKEN_BALANCES = "SET_UI_TOKEN_BALANCES";
export const SET_UI_TOKEN_BALANCE = "SET_UI_TOKEN_BALANCE";

// Admin related actions
export const SET_ADMIN_CURRENT_USER = "SET_ADMIN_CURRENT_USER";
