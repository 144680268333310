import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = ({ element }) => {
  const auth = useSelector((state) => state.auth);
  const isAuthenticated = auth?.token && auth?.tfa_validated;

  return !isAuthenticated ? element : <Navigate to="/" />;
};

export default PublicRoute;
