import axios from "axios";

const API = axios.create({ baseURL: "" });
API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${localStorage.getItem("profile")}`;
  }
  return req;
});

// auth
export const signIn = (data) => API.post("/auth/signin", data);
export const googleSignIn = (data) => API.post("/auth/google-signin", data);
export const signUp = (data) => API.post("/auth/signup", data);
export const otpVerify = (data) => API.post("/auth/otp-verify", data);
export const lockUser = () => API.put("/auth/lock-user");
export const unlockUser = (data) => API.put("/auth/unlock-user", data);
export const changePassword = (data) => API.put("/auth/change-password", data);
export const unlock = (data) => API.post("/auth/unlock", data);

// 2fa
export const generate2faOTP = () => API.post("/auth/generate-2fa-otp");
export const verify2faOTP = (data) => API.post("/auth/verify-2fa-otp", data);
export const validate2faOTP = (data) =>
  API.post("/auth/validate-2fa-otp", data);
export const disable2faOTP = (data) => API.put("/auth/disable-2fa-otp", data);

// settings
export const changeSettings = (data) => API.put("/auth/change-settings", data);

// user
export const transfer = (data) => API.post("/user/transfer", data);
export const getUserByEmail = (data) => API.post("/email", data);
export const updateUserOffchainInfo = (data) =>
  API.put("/user/update-offchain-info", data);
export const queryUserOffchainTrxs = (data) =>
  API.post("/user/query-offchain-trx-list", data);
export const getUserById = (data) => API.get(`/user/${data}`);

// admin
// users
export const getUsers = () => API.get("/admin/users");
export const changeUserInfo = (data) =>
  API.put("/admin/change-user-info", data);
export const changeUserPassword = (data) =>
  API.put("/admin/change-user-password", data);
// schedules
export const getSchedules = () => API.get("/admin/schedules");
export const scheduleBalanceChange = (data) =>
  API.post("/admin/schedule-balance-change", data);
export const cancelSchedule = (data) =>
  API.post("/admin/cancel-schedule", data);
export const deleteTransaction = (id, data) =>
  API.put(`/admin/transaction/${id}`, data);
export const importOnChainTransactionHistory = (data) =>
  API.post("/admin/import-onchain-transactions", data);
export const deleteAllSentTransactoins = (data) =>
  API.delete(`/admin/delete-all-sent-transactions/${data}`);
