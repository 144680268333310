import {
  SET_USER,
  SET_TOKEN,
  LOGOUT,
  SET_TFA_VALIDATED,
  SET_LOADING,
} from "src/constants/actionTypes";

const initialState = {
  user: null, // Initial value
  tfa_validated: false,
  token: null,
  loading: false,
};

const authReducer = (state = initialState, action) => {
  console.log("reducer: action", action);
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };

    case SET_TOKEN:
      return { ...state, token: action.payload };

    case SET_TFA_VALIDATED:
      if (action.payload) {
        localStorage.setItem("profile", state.token);
      }
      return { ...state, tfa_validated: action.payload };

    case LOGOUT:
      localStorage.clear();
      return { ...state, user: null };

    case SET_LOADING:
      return { ...state, loading: action.payload };

    default:
      return state;
  }
};

export default authReducer;
